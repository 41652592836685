import { useState, useEffect } from "react";

import {
  tailwindBreakpointsMap,
  Breakpoint,
} from "apps/website/maps/Breakpoint.map";

const getActiveBreakpoint = (windowWidth: number) => {
  let currentBreakpoint: keyof typeof tailwindBreakpointsMap = "default";
  for (const [ key, value ] of Object.entries(tailwindBreakpointsMap)) {
    if (windowWidth >= Number(value) && Number(value) >= tailwindBreakpointsMap[currentBreakpoint]) {
      currentBreakpoint = key as keyof typeof tailwindBreakpointsMap;
    }
  }
  return currentBreakpoint;
};

export default function useWindowSize() {

  const [ windowSize, setWindowSize ] = useState({
    width: 0,
    height: 0,
    activeBreakpoint: "",
  });

  const isActiveBreakpointAbove = (breakpoint: Breakpoint): boolean => {
    const keys = Object.keys(tailwindBreakpointsMap);
    return keys.indexOf(windowSize.activeBreakpoint) >= keys.indexOf(breakpoint);
  };

  const isActiveBreakpointBelow = (breakpoint: Breakpoint): boolean => {
    const keys = Object.keys(tailwindBreakpointsMap);
    return keys.indexOf(windowSize.activeBreakpoint) < keys.indexOf(breakpoint);
  };

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        activeBreakpoint: getActiveBreakpoint(window.innerWidth),
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return {
    windowSize,
    isActiveBreakpointAbove,
    isActiveBreakpointBelow,
  };
}
