"use client";

import {
  FC,
  useEffect,
  useRef,
  memo,
  useState,
  CSSProperties,
  useCallback,
} from "react";

import useWindowSize from "apps/website/hooks/useWindowSize";
import { WithTestID } from "apps/website/types";
import cx from "apps/website/utils/misc/cx";

interface IImageResponsiveSrc {
  extraSmall?: string;
  mobile?: string;
  desktop?: string;
  extraLarge?: string;
}

export interface IImageProps {
  src: string;
  width: number;
  height: number;
  alt?: string;
  responsiveSrc?: IImageResponsiveSrc,
}

export interface IAdvancedImages {
  extraSmall?: IImageProps;
  mobile?: IImageProps;
  desktop?: IImageProps;
  extraLarge?: IImageProps;
}

export interface IImage extends WithTestID {
  image: IImageProps;
  advancedImages?: IAdvancedImages;
  alt: string;
  lazy?: boolean;
  cover?: boolean;
  className?: string;
  imageClassName?: string;
  objectPosition?: string;
}

const getGreatestCommonDivisor = (width: number, height: number): number => (
  width % height ? getGreatestCommonDivisor(height, width % height) : height
);

export const ratio = (width: number, height: number): string => {
  const gcd: number = getGreatestCommonDivisor(width, height);
  return `${width / gcd}/${height / gcd}`;
};

const getSourceImage = (
  defaultImage: IImageProps,
  advancedImage: IImageProps | undefined,
  responsiveFallback: string,
): IImageProps => (advancedImage?.src ? advancedImage : {
  src: responsiveFallback,
  width: defaultImage.width,
  height: defaultImage.height,
});

const Image: FC<IImage> = ({ "data-testid": testId, image, advancedImages, lazy = true, alt, cover = false, className, imageClassName, objectPosition = "object-center" }) => {

  const imageElement = useRef<HTMLImageElement>(null);
  const preloadedClasses = "opacity-0";
  const loadedClasses = "opacity-1";
  const [ imageRatio, setImageRatio ] = useState<string>();
  const [ wrapperClasses, setWrapperClasses ] = useState<CSSProperties>({});
  const { windowSize } = useWindowSize();

  const getCorrectActiveBreakpointImage = useCallback((): IImageProps => {
    if (!advancedImages) return image;
    if (windowSize.activeBreakpoint === "sm") {
      if (advancedImages.extraSmall) {
        return advancedImages.extraSmall;
      }
    }
    if (windowSize.activeBreakpoint === "md") {
      if (advancedImages.mobile) {
        return advancedImages.mobile;
      }
      if (advancedImages.extraSmall) {
        return advancedImages.extraSmall;
      }
    }
    if (windowSize.activeBreakpoint === "lg") {
      if (advancedImages.mobile) {
        return advancedImages.mobile;
      }
      if (advancedImages.extraSmall) {
        return advancedImages.extraSmall;
      }
    }
    if ([ "xl", "2xl" ].includes(windowSize.activeBreakpoint)) {
      if (advancedImages.desktop) {
        return advancedImages.desktop;
      }
    }
    if ([ "3xl" ].includes(windowSize.activeBreakpoint)) {
      if (advancedImages.extraLarge) {
        return advancedImages.extraLarge;
      }
      if (advancedImages.desktop) {
        return advancedImages.desktop;
      }
    }
    return image;
  }, [ windowSize.activeBreakpoint, advancedImages, image ]);

  useEffect(() => {
    if (!image) {
      return;
    }
    let getRatio = ratio(image?.width, image?.height);
    if (advancedImages) {
      getRatio = ratio(getCorrectActiveBreakpointImage().width, getCorrectActiveBreakpointImage().height);
    }
    if (imageRatio !== getRatio) {
      setImageRatio(getRatio);
    }
  }, [ advancedImages, image, cover, getCorrectActiveBreakpointImage ]);

  useEffect(() => {
    const classes = cover ? {} : { aspectRatio: imageRatio };
    setWrapperClasses(classes);
  }, [ cover, imageRatio ]);

  // eslint-disable-next-line
  const loadImage = (): any => { // This is an expected any
    imageElement.current?.setAttribute(
      "loaded",
      "true",
    );
    preloadedClasses.split(" ").forEach((imageClass: string) => {
      imageElement.current?.classList.remove(imageClass);
    });
    loadedClasses.split(" ").forEach((imageClass: string) => {
      imageElement.current?.classList.add(imageClass);
    });
  };
  useEffect(() => {
    const imageRef = imageElement.current; // Needs to be set for cleanup
    imageRef?.addEventListener("load", loadImage());
    return () => {
      imageRef?.removeEventListener("load", loadImage());
    };
  }, []);

  const getSourceImages = useCallback((): IAdvancedImages | undefined => {
    if (image) {
      return { extraSmall: getSourceImage(image, advancedImages?.extraSmall, image?.responsiveSrc?.extraSmall ?? ""),
        mobile: getSourceImage(image, advancedImages?.mobile, image?.responsiveSrc?.mobile ?? ""),
        desktop: getSourceImage(image, advancedImages?.desktop, image?.responsiveSrc?.desktop ?? ""),
        extraLarge: getSourceImage(image, advancedImages?.extraLarge, image?.responsiveSrc?.extraLarge ?? ""),
      };
    }
  }, [ advancedImages, image ]);

  return (
    <div
      data-component="Image"
      className={cx(
        "bg-gray-200 w-full",
        { "w-full h-full absolute top-0 left-0": cover },
        className,
      )}
      style={wrapperClasses}
    >
      <picture>
        <source media="(max-width:420px)" width={getSourceImages()?.extraSmall?.width} height={getSourceImages()?.extraSmall?.height} srcSet={getSourceImages()?.extraSmall?.src} />
        <source media="(max-width:767px)" width={getSourceImages()?.mobile?.width} height={getSourceImages()?.mobile?.height} srcSet={getSourceImages()?.mobile?.src} />
        <source media="(max-width:2200px)" width={getSourceImages()?.desktop?.width} height={getSourceImages()?.desktop?.height} srcSet={getSourceImages()?.desktop?.src} />
        <source media="(min-width:2201px)" width={getSourceImages()?.extraLarge?.width} height={getSourceImages()?.extraLarge?.height} srcSet={getSourceImages()?.extraLarge?.src} />
        <img
          ref={imageElement}
          src={image?.src}
          alt={alt}
          height={image?.height}
          width={image?.width}
          loading={lazy ? "lazy" : "eager"}
          className={ `w-full transition-opacity duration-1000 opacity-0 ${cover && `object-cover absolute top-0 left-0 w-full h-full ${objectPosition}`} ${imageClassName}`}
          data-testid={testId}
        />
      </picture>
    </div>
  );
};

export default memo(Image);
