import { FC, ReactNode, memo } from "react";
import Link from "next/link";

import Text from "apps/website/components/base/Text/Text";
import {
  designClassMap,
  ButtonComponent,
  Color,
  Design,
  Type,
  Size,
  sizeClassMap,
} from "apps/website/components/base/Button/Button.map";
import { DisplayState } from "@/constants/state";
import { WithTestID } from "apps/website/types";
import cx from "apps/website/utils/misc/cx";

import Icon from "../Icon/Icon";

import ButtonState from "./ButtonState";

export interface IButton extends WithTestID {
  state?: DisplayState;
  id?: string;
  ariaControls?: string;
  ariaExpanded?: boolean;
  to?: string;
  type?: Type;
  size?: Size;
  design?: Design;
  color?: Color;
  disabled?: boolean;
  onClick?: () => void;
  className?: string;
  component?: string;
  children: ReactNode;
  withIcon?: boolean; // Relevant for links
}

export interface IButtonIcon {
  to?: string;
}

const ButtonIcon: FC<IButtonIcon> = ({ to }) => {
  if (!to) return <></>;
  return (
    <div className={cx("h-5 w-5 flex items-center justify-center ml-2")}>
      <Icon size="fit" icon={ to.startsWith("#") ? "arrowThinDown" : "arrowRightShort" } color="inherit" />
    </div>
  );
};

const Button: FC<IButton> = ({
  color = "default",
  id,
  "data-testid": testId,
  ariaControls,
  ariaExpanded,
  state = DisplayState.READY,
  design = "default",
  to,
  type = "button",
  size = "default",
  disabled = false,
  onClick,
  className = "",
  component = "Button",
  withIcon = false,
  children,
}) => {

  const componentElement: ButtonComponent = to ? ButtonComponent.LINK : ButtonComponent.DEFAULT;
  const computedClasses = `${designClassMap[design].base} ${designClassMap[design][color]} ${sizeClassMap[size]} ${className}`;

  // Link
  const isExternalLink = to?.startsWith("/manage-plan")
    || (!to?.startsWith("/") && !to?.startsWith("#") && !to?.startsWith("?"));
  const target = isExternalLink ? "_blank" : undefined;
  const rel = isExternalLink ? "nofollow noopener" : undefined;

  const AnchorTag = () => (
    <a
      data-component={ component }
      href={ disabled ? "" : to }
      data-disabled={ disabled }
      target={ target }
      rel={ rel }
      className={ computedClasses }
      id={ id }
      data-testid={ testId }
      onClick={ onClick }
    >
      <Text tag="span" display="button" color="inherit" className="flex justify-center items-center">
        <ButtonState state={ state }>{ children }{ withIcon ? <ButtonIcon to={to} /> : null }</ButtonState>
      </Text>
    </a>
  );

  return <>
    { componentElement === ButtonComponent.DEFAULT && (
      <button
        data-component={ component }
        id={ id }
        data-testid={ testId }
        type={ type }
        className={ computedClasses }
        disabled={ disabled }
        onClick={ onClick }
        aria-controls={ ariaControls }
        aria-expanded={ ariaExpanded }
      >
        <Text tag="span" display="button" color="inherit" align="center" className="flex justify-center items-center">
          <ButtonState state={ state }>{ children }</ButtonState>
        </Text>
      </button>
    ) }
    { componentElement === ButtonComponent.LINK && (
      <>
        { isExternalLink || !to ? (
          <AnchorTag />
        ) : (
          <Link href={ disabled ? "" : to } legacyBehavior>
            <AnchorTag />
          </Link>
        ) }
      </>
    ) }
  </>;
};

export default memo(Button);
